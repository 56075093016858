var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _vm._l(_vm.extraPayments, function (payment, index) {
        return _c(
          "v-row",
          { key: payment._id, staticClass: "extra-payment-item align-center" },
          [
            _c(
              "v-col",
              { staticClass: "extra-payment-status" },
              [
                _c("payment-status", {
                  attrs: {
                    editable: true,
                    documents: _vm.documents,
                    paymentItem: payment,
                  },
                  on: {
                    save: function ($event) {
                      return _vm.updateExtraPaymentStatus(
                        $event,
                        payment._id,
                        index
                      )
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              {
                staticClass: "text-truncate",
                attrs: { sm: "3", md: "3", title: payment.title },
              },
              [_vm._v(_vm._s(payment.title))]
            ),
            _c(
              "v-col",
              {
                staticClass: "text-truncate",
                attrs: { title: payment.payToName },
              },
              [_vm._v(_vm._s(payment.payToName))]
            ),
            _c(
              "v-col",
              {
                staticClass: "text-truncate",
                attrs: { title: payment.payToBankAccount },
              },
              [_vm._v(_vm._s(payment.payToBankAccount))]
            ),
            _c("v-col", { attrs: { sm: "1", md: "1" } }, [
              _vm._v(_vm._s(payment.amount)),
            ]),
            _c(
              "v-col",
              {
                staticClass: "d-flex justify-end align-center",
                attrs: { sm: "1", md: "1" },
              },
              [
                _c(
                  "v-icon",
                  {
                    attrs: { color: "grey" },
                    on: {
                      click: function ($event) {
                        ;(_vm.confirmDeleteDialog = true),
                          (_vm.confirmDeleteDialogItem = payment),
                          (_vm.extraPaymentIndex = index)
                      },
                    },
                  },
                  [_vm._v(" mdi-delete ")]
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "v-btn",
        {
          staticClass: "my-2",
          attrs: { outlined: "", color: "grey" },
          on: { click: _vm.addOneTimePayment },
        },
        [_vm._v(" Éénmalige betaling toevoegen ")]
      ),
      _vm._l(_vm.newExtraPayments, function (payment, index) {
        return _c(
          "v-row",
          { key: payment.id },
          [
            _c(
              "v-col",
              { attrs: { cols: "12", sm: "12" } },
              [
                _c(
                  "v-card",
                  { staticClass: "pa-3 mt-4" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "8", md: "8" } },
                          [
                            _c("v-select", {
                              attrs: {
                                outlined: "",
                                label: "Betaalkeuze",
                                items: _vm.paymentTypes,
                                "item-value": "id",
                                "item-text": "label",
                                required: "",
                                "hide-details": "",
                              },
                              model: {
                                value: payment.paymentMethod,
                                callback: function ($$v) {
                                  _vm.$set(payment, "paymentMethod", $$v)
                                },
                                expression: "payment.paymentMethod",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex justify-end align-center",
                            attrs: { cols: "12", sm: "2", md: "2" },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mx-2",
                                attrs: { color: "grey" },
                                on: {
                                  click: function ($event) {
                                    return _vm.newExtraPayments.splice(index, 1)
                                  },
                                },
                              },
                              [_vm._v(" mdi-delete ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "12", md: "23" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                label: "Titel",
                                type: "text",
                                required: "",
                                "hide-details": "",
                              },
                              model: {
                                value: payment.title,
                                callback: function ($$v) {
                                  _vm.$set(payment, "title", $$v)
                                },
                                expression: "payment.title",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6", md: "6" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                outlined: "",
                                label: "Company / Eigenaar",
                                items: _vm.getCompanies(payment.paymentMethod),
                                "item-value": "_id",
                                "item-text": "name",
                                required: "",
                                "hide-details": "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onCompanyChange(
                                    payment.ownerCompanyId,
                                    payment
                                  )
                                },
                              },
                              model: {
                                value: payment.ownerCompanyId,
                                callback: function ($$v) {
                                  _vm.$set(payment, "ownerCompanyId", $$v)
                                },
                                expression: "payment.ownerCompanyId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6", md: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                label: "Bedrag",
                                type: "number",
                                required: "",
                                "hide-details": "",
                              },
                              model: {
                                value: payment.amount,
                                callback: function ($$v) {
                                  _vm.$set(payment, "amount", $$v)
                                },
                                expression: "payment.amount",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6", md: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                disabled: true,
                                label: "Naam rekeninghouder",
                                required: "",
                                "hide-details": "",
                              },
                              model: {
                                value: payment.payToName,
                                callback: function ($$v) {
                                  _vm.$set(payment, "payToName", $$v)
                                },
                                expression: "payment.payToName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6", md: "6" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                disabled: true,
                                label: "Rekeningnummer",
                                required: "",
                                "hide-details": "",
                              },
                              model: {
                                value: payment.payToBankAccount,
                                callback: function ($$v) {
                                  _vm.$set(payment, "payToBankAccount", $$v)
                                },
                                expression: "payment.payToBankAccount",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.confirmDeleteDialog,
            callback: function ($$v) {
              _vm.confirmDeleteDialog = $$v
            },
            expression: "confirmDeleteDialog",
          },
        },
        [
          _vm.confirmDeleteDialog
            ? _c("confirm-delete", {
                attrs: { item: _vm.confirmDeleteDialogItem },
                on: {
                  cancel: function ($event) {
                    _vm.confirmDeleteDialogItem = null
                    _vm.confirmDeleteDialog = false
                  },
                  delete: _vm.deleteExtraPayment,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }