var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _vm.editItem
          ? _c("h3", [_vm._v("Contract aanpassen")])
          : !_vm.extension
          ? _c("h3", [_vm._v("Contract aanmaken")])
          : _c("h3", [_vm._v("Contract verlengen")]),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "pa-3" },
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12", sm: "12" } }, [
                            _c("h3", { staticClass: "black--text" }, [
                              _vm._v("Contract gegevens"),
                            ]),
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", lg: "6" },
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu1",
                                  attrs: {
                                    "close-on-content-click": false,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "auto",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    disabled: _vm.editItem,
                                                    label: "Start datum",
                                                    "persistent-hint": "",
                                                    "prepend-icon":
                                                      "mdi-calendar",
                                                  },
                                                  model: {
                                                    value: _vm.dateFormatted,
                                                    callback: function ($$v) {
                                                      _vm.dateFormatted = $$v
                                                    },
                                                    expression: "dateFormatted",
                                                  },
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.menu1,
                                    callback: function ($$v) {
                                      _vm.menu1 = $$v
                                    },
                                    expression: "menu1",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: { "no-title": "" },
                                    on: {
                                      input: function ($event) {
                                        _vm.menu1 = false
                                      },
                                    },
                                    model: {
                                      value: _vm.date,
                                      callback: function ($$v) {
                                        _vm.date = $$v
                                      },
                                      expression: "date",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", lg: "6" },
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu2",
                                  attrs: {
                                    "close-on-content-click": false,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "auto",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    label: "Eind datum",
                                                    "persistent-hint": "",
                                                    "prepend-icon":
                                                      "mdi-calendar",
                                                  },
                                                  model: {
                                                    value: _vm.endDateFormatted,
                                                    callback: function ($$v) {
                                                      _vm.endDateFormatted = $$v
                                                    },
                                                    expression:
                                                      "endDateFormatted",
                                                  },
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.menu2,
                                    callback: function ($$v) {
                                      _vm.menu2 = $$v
                                    },
                                    expression: "menu2",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      "picker-date": _vm.defaultValue,
                                      min: _vm.date,
                                      "no-title": "",
                                    },
                                    on: {
                                      "update:pickerDate": function ($event) {
                                        _vm.defaultValue = $event
                                      },
                                      "update:picker-date": function ($event) {
                                        _vm.defaultValue = $event
                                      },
                                      input: function ($event) {
                                        _vm.menu2 = false
                                      },
                                    },
                                    model: {
                                      value: _vm.endDate,
                                      callback: function ($$v) {
                                        _vm.endDate = $$v
                                      },
                                      expression: "endDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", sm: "11" },
                            },
                            [
                              _c("v-autocomplete", {
                                staticClass: "pt-0",
                                attrs: {
                                  label: "Selecteer een huurder",
                                  items: _vm.tenantNames,
                                  "item-text": "fullName",
                                  "item-value": "_id",
                                  rules: [
                                    () =>
                                      !!_vm.tenant || "Dit veld is verplicht",
                                  ],
                                  disabled: _vm.extension,
                                },
                                model: {
                                  value: _vm.tenant,
                                  callback: function ($$v) {
                                    _vm.tenant = $$v
                                  },
                                  expression: "tenant",
                                },
                              }),
                            ],
                            1
                          ),
                          !_vm.extension && !_vm.editItem
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "py-0",
                                  attrs: { cols: "12", sm: "1" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-3",
                                      attrs: { color: "black" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showCreateTenant = true
                                        },
                                      },
                                    },
                                    [_vm._v(" mdi-account-plus ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showCreateTenant
                            ? _c(
                                "v-card",
                                { staticClass: "mb-10" },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-flex",
                                        { staticClass: "pa-3" },
                                        [
                                          _c(
                                            "v-form",
                                            { ref: "form" },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass: "ml-2 my-3",
                                                    },
                                                    [_vm._v("Huurder aanmaken")]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "pt-0",
                                                        attrs: {
                                                          label:
                                                            "Tenant Id (from omnibox)*",
                                                          clearable: "",
                                                          rules: [
                                                            () =>
                                                              !!_vm.tenantID ||
                                                              "Dit veld is verplicht",
                                                          ],
                                                        },
                                                        model: {
                                                          value: _vm.tenantID,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.tenantID = $$v
                                                          },
                                                          expression:
                                                            "tenantID",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "my-n6",
                                                      attrs: {
                                                        cols: "12",
                                                        lg: "12",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          ref: "menu3",
                                                          attrs: {
                                                            "close-on-content-click": false,
                                                            transition:
                                                              "scale-transition",
                                                            "offset-y": "",
                                                            "max-width":
                                                              "290px",
                                                            "min-width": "auto",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                  attrs,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Geboortedatum",
                                                                                "persistent-hint":
                                                                                  "",
                                                                                "prepend-icon":
                                                                                  "mdi-calendar",
                                                                              },
                                                                            on: {
                                                                              blur: function (
                                                                                $event
                                                                              ) {
                                                                                _vm.date =
                                                                                  _vm.parseDate(
                                                                                    _vm.dateOfBirthFormatted
                                                                                  )
                                                                              },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm.dateOfBirthFormatted,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.dateOfBirthFormatted =
                                                                                      $$v
                                                                                  },
                                                                                expression:
                                                                                  "dateOfBirthFormatted",
                                                                              },
                                                                          },
                                                                          "v-text-field",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            2049235791
                                                          ),
                                                          model: {
                                                            value: _vm.menu3,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.menu3 = $$v
                                                            },
                                                            expression: "menu3",
                                                          },
                                                        },
                                                        [
                                                          _c("v-date-picker", {
                                                            attrs: {
                                                              "no-title": "",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                _vm.menu3 = false
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.dateOfBirth,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.dateOfBirth =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "dateOfBirth",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "pt-0",
                                                        attrs: {
                                                          label: "Voornaam*",
                                                          clearable: "",
                                                          rules: [
                                                            () =>
                                                              !!_vm.firstName ||
                                                              "Dit veld is verplicht",
                                                          ],
                                                        },
                                                        model: {
                                                          value: _vm.firstName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.firstName = $$v
                                                          },
                                                          expression:
                                                            "firstName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "pt-0",
                                                        attrs: {
                                                          label: "Naam*",
                                                          clearable: "",
                                                          rules: [
                                                            () =>
                                                              !!_vm.lastName ||
                                                              "Dit veld is verplicht",
                                                          ],
                                                        },
                                                        model: {
                                                          value: _vm.lastName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.lastName = $$v
                                                          },
                                                          expression:
                                                            "lastName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "pt-0",
                                                        attrs: {
                                                          label: "E-mail*",
                                                          clearable: "",
                                                          rules: _vm.emailRules,
                                                        },
                                                        model: {
                                                          value: _vm.email,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.email = $$v
                                                          },
                                                          expression: "email",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "pt-0",
                                                        attrs: {
                                                          label:
                                                            "E-mail van een ouder",
                                                          clearable: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.emailParent,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.emailParent =
                                                              $$v
                                                          },
                                                          expression:
                                                            "emailParent",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6",
                                                      },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        staticClass: "pt-0",
                                                        attrs: {
                                                          items: _vm.languages,
                                                          "item-text": "name",
                                                          "item-value": "_id",
                                                          label:
                                                            "Selecteer een taal",
                                                          clearable: "",
                                                          rules: [
                                                            () =>
                                                              !!_vm.language ||
                                                              "Dit veld is verplicht",
                                                          ],
                                                        },
                                                        model: {
                                                          value: _vm.language,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.language = $$v
                                                          },
                                                          expression:
                                                            "language",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "py-0 mb-3 d-flex justify-end",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mb-2",
                                                          attrs: {
                                                            color:
                                                              "primary mr-2",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.showCreateTenant = false
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Annuleren")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mb-2",
                                                          attrs: {
                                                            disabled:
                                                              !_vm.isTenantComplete,
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.createTenant,
                                                          },
                                                        },
                                                        [_vm._v("Opslaan")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", sm: "6" },
                            },
                            [
                              _c("v-select", {
                                staticClass: "pt-0",
                                attrs: {
                                  label:
                                    _vm.projectNameLabel || "Project naam*",
                                  items: _vm.projectNames,
                                  "item-text": "name",
                                  "item-value": "_id",
                                  rules: [
                                    () =>
                                      !!_vm.projectName ||
                                      "Dit veld is verplicht",
                                  ],
                                  disabled: _vm.extension,
                                },
                                on: { change: _vm.filteredProjectNames },
                                model: {
                                  value: _vm.projectName,
                                  callback: function ($$v) {
                                    _vm.projectName = $$v
                                  },
                                  expression: "projectName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", sm: "6" },
                            },
                            [
                              _c("v-autocomplete", {
                                staticClass: "pt-0",
                                attrs: {
                                  label: _vm.unitNameLabel || "Unit naam*",
                                  clearable: "",
                                  items: _vm.unitNames,
                                  "item-text": "name",
                                  "item-value": "_id",
                                  rules: [
                                    () =>
                                      !!_vm.unitNames ||
                                      "Dit veld is verplicht",
                                  ],
                                  disabled: _vm.unitNameDisabled,
                                },
                                on: { change: _vm.getUnitPrice },
                                model: {
                                  value: _vm.unitName,
                                  callback: function ($$v) {
                                    _vm.unitName = $$v
                                  },
                                  expression: "unitName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", sm: "6" },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pt-0",
                                attrs: {
                                  label: "Huurprijs",
                                  clearable: "",
                                  rules: [
                                    () =>
                                      !!_vm.rentPrice ||
                                      "Dit veld is verplicht",
                                  ],
                                },
                                model: {
                                  value: _vm.rentPrice,
                                  callback: function ($$v) {
                                    _vm.rentPrice = $$v
                                  },
                                  expression: "rentPrice",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.extension && !_vm.editItem
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "py-0",
                                  attrs: { cols: "12", sm: "12" },
                                },
                                [
                                  _vm._v(" Eenmalige betalingen "),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c("v-switch", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          flat: "",
                                          label: "Huur",
                                          disabled: _vm.switchRent == null,
                                        },
                                        model: {
                                          value: _vm.switchRent,
                                          callback: function ($$v) {
                                            _vm.switchRent = $$v
                                          },
                                          expression: "switchRent",
                                        },
                                      }),
                                      _c("v-switch", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          flat: "",
                                          label: "Waarborg",
                                          disabled: _vm.switchDeposit == null,
                                        },
                                        model: {
                                          value: _vm.switchDeposit,
                                          callback: function ($$v) {
                                            _vm.switchDeposit = $$v
                                          },
                                          expression: "switchDeposit",
                                        },
                                      }),
                                      _c("v-switch", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          flat: "",
                                          label: "EGWI",
                                          disabled: _vm.switchEGWI == null,
                                        },
                                        model: {
                                          value: _vm.switchEGWI,
                                          callback: function ($$v) {
                                            _vm.switchEGWI = $$v
                                          },
                                          expression: "switchEGWI",
                                        },
                                      }),
                                      _c("v-switch", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          flat: "",
                                          label: "Plaatsbeschrijving",
                                          disabled: _vm.switchInventory == null,
                                        },
                                        model: {
                                          value: _vm.switchInventory,
                                          callback: function ($$v) {
                                            _vm.switchInventory = $$v
                                          },
                                          expression: "switchInventory",
                                        },
                                      }),
                                      _c("v-switch", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          label: "Taks",
                                          flat: "",
                                          disabled:
                                            _vm.switchStudenttax == null,
                                        },
                                        model: {
                                          value: _vm.switchStudenttax,
                                          callback: function ($$v) {
                                            _vm.switchStudenttax = $$v
                                          },
                                          expression: "switchStudenttax",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          !_vm.editItem
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "py-0",
                                  attrs: { cols: "12", sm: "12" },
                                },
                                [
                                  _c("v-textarea", {
                                    staticClass: "pt-0",
                                    attrs: {
                                      "auto-grow": "",
                                      rows: "5",
                                      "row-height": "22",
                                      label: "Opmerkingen",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.contractComments,
                                      callback: function ($$v) {
                                        _vm.contractComments = $$v
                                      },
                                      expression: "contractComments",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.editItem &&
                          _vm.hasPaidEcurringFees &&
                          (!_vm.item.eCurringSubscriptions ||
                            _vm.item.eCurringSubscriptions.length == 0)
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "py-0",
                                  attrs: { cols: "12", sm: "12" },
                                },
                                [
                                  _c("p", { staticClass: "red--text" }, [
                                    _vm._v(
                                      "Om het eCurring bankaccount aan te passen zal je dit manueel moeten doen"
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { col: "12", sm: "6" },
                            },
                            [
                              _vm.extension && _vm.editItem
                                ? _c("v-select", {
                                    attrs: {
                                      label: "Status Inschrijvings Document",
                                      items: _vm.regDocumentStatuses,
                                      "item-text": "name",
                                      "item-value": "_id",
                                    },
                                    model: {
                                      value: _vm.registrationDocument,
                                      callback: function ($$v) {
                                        _vm.registrationDocument = $$v
                                      },
                                      expression: "registrationDocument",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.editItem &&
                          !_vm.hasPaidEcurringFees &&
                          (!_vm.item.eCurringSubscriptions ||
                            _vm.item.eCurringSubscriptions.length == 0)
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "py-0",
                                  attrs: { cols: "12", sm: "6" },
                                },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label:
                                        "Startdatum eCurring overschrijven",
                                    },
                                    model: {
                                      value: _vm.ecurringDateSwitch,
                                      callback: function ($$v) {
                                        _vm.ecurringDateSwitch = $$v
                                      },
                                      expression: "ecurringDateSwitch",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.ecurringDateSwitch && _vm.editItem
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pt-6 py-0",
                                  attrs: { cols: "12", sm: "6" },
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "menu5",
                                      attrs: {
                                        "close-on-content-click": false,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "max-width": "290px",
                                        "min-width": "auto",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          label:
                                                            "Nieuwe startdatum eCurring",
                                                          "persistent-hint": "",
                                                          "prepend-icon":
                                                            "mdi-calendar",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ecurringDateFormatted,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.ecurringDateFormatted =
                                                              $$v
                                                          },
                                                          expression:
                                                            "ecurringDateFormatted",
                                                        },
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3862439629
                                      ),
                                      model: {
                                        value: _vm.menu5,
                                        callback: function ($$v) {
                                          _vm.menu5 = $$v
                                        },
                                        expression: "menu5",
                                      },
                                    },
                                    [
                                      _c("v-date-picker", {
                                        attrs: { "no-title": "" },
                                        on: {
                                          input: function ($event) {
                                            _vm.menu5 = false
                                          },
                                        },
                                        model: {
                                          value: _vm.ecurringDate,
                                          callback: function ($$v) {
                                            _vm.ecurringDate = $$v
                                          },
                                          expression: "ecurringDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-6 py-0",
                              attrs: { cols: "12", sm: "6" },
                            },
                            [
                              _vm.newContractStartDate &&
                              _vm.editItem &&
                              !_vm.ecurringDateSwitch
                                ? _c("v-text-field", {
                                    attrs: {
                                      label: "Nieuwe startdatum eCurring",
                                      "persistent-hint": "",
                                      "prepend-icon": "mdi-calendar",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.newContractStartDate,
                                      callback: function ($$v) {
                                        _vm.newContractStartDate = $$v
                                      },
                                      expression: "newContractStartDate",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.editItem &&
                          _vm.item.eCurringSubscriptions &&
                          _vm.item.eCurringSubscriptions.length > 0
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "py-0",
                                  attrs: { cols: "12", sm: "12" },
                                },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "eCurring reset" },
                                    model: {
                                      value: _vm.resetEcurringSwitch,
                                      callback: function ($$v) {
                                        _vm.resetEcurringSwitch = $$v
                                      },
                                      expression: "resetEcurringSwitch",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.resetEcurringSwitch && _vm.editItem
                            ? _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-flex",
                                        { staticClass: "pa-3" },
                                        [
                                          _c(
                                            "v-form",
                                            { ref: "form" },
                                            _vm._l(
                                              _vm.overrideEcurringSubscriptions,
                                              function (eCurringItem) {
                                                return _c(
                                                  "v-row",
                                                  { key: eCurringItem._id },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "mb-n4 d-flex",
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c("v-switch", {
                                                              staticClass:
                                                                "mt-0 mr-2",
                                                              attrs: {
                                                                inset: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  eCurringItem.switch,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      eCurringItem,
                                                                      "switch",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "eCurringItem.switch",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c("div", [
                                                          _c("h2", [
                                                            _vm._v(
                                                              _vm._s(
                                                                eCurringItem.paymentType
                                                              )
                                                            ),
                                                          ]),
                                                          _c("p", [
                                                            _vm._v(
                                                              "Mandaat werd "
                                                            ),
                                                            _vm._o(
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "formatDate"
                                                                      )(
                                                                        eCurringItem.startDate
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              0,
                                                              eCurringItem._id
                                                            ),
                                                            _vm._v(
                                                              " gestart. "
                                                            ),
                                                          ]),
                                                        ]),
                                                      ]
                                                    ),
                                                    eCurringItem.switch
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "pt-0 py-0",
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-menu",
                                                              {
                                                                attrs: {
                                                                  "close-on-content-click": false,
                                                                  transition:
                                                                    "scale-transition",
                                                                  "offset-y":
                                                                    "",
                                                                  "max-width":
                                                                    "290px",
                                                                  "min-width":
                                                                    "auto",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-text-field",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        value:
                                                                                          _vm.computedDateFormattedMomentjs(
                                                                                            eCurringItem.startDate
                                                                                          ),
                                                                                        label:
                                                                                          "Nieuwe eCurring startdatum",
                                                                                        readonly:
                                                                                          "",
                                                                                      },
                                                                                  },
                                                                                  "v-text-field",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              )
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  "v-date-picker",
                                                                  {
                                                                    model: {
                                                                      value:
                                                                        eCurringItem.startDate,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            eCurringItem,
                                                                            "startDate",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "eCurringItem.startDate",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    eCurringItem.switch
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "pt-3",
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "3",
                                                              md: "3",
                                                            },
                                                          },
                                                          [
                                                            _vm._o(
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  staticClass:
                                                                    "pt-0",
                                                                  attrs: {
                                                                    label:
                                                                      "Bankrekeningnummer",
                                                                    clearable:
                                                                      "",
                                                                    rules:
                                                                      _vm.bankAccountRules,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      eCurringItem.bankAccount,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          eCurringItem,
                                                                          "bankAccount",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "eCurringItem.bankAccount",
                                                                  },
                                                                }
                                                              ),
                                                              1,
                                                              eCurringItem._id
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    eCurringItem.switch
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "pt-3",
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "3",
                                                              md: "3",
                                                            },
                                                          },
                                                          [
                                                            _vm._o(
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  staticClass:
                                                                    "pt-0",
                                                                  attrs: {
                                                                    label:
                                                                      "Voornaam",
                                                                    clearable:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      eCurringItem.firstName,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          eCurringItem,
                                                                          "firstName",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "eCurringItem.firstName",
                                                                  },
                                                                }
                                                              ),
                                                              2,
                                                              eCurringItem._id
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    eCurringItem.switch
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "pt-3",
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "3",
                                                              md: "3",
                                                            },
                                                          },
                                                          [
                                                            _vm._o(
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  staticClass:
                                                                    "pt-0",
                                                                  attrs: {
                                                                    label:
                                                                      "Achternaam",
                                                                    clearable:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      eCurringItem.lastName,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          eCurringItem,
                                                                          "lastName",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "eCurringItem.lastName",
                                                                  },
                                                                }
                                                              ),
                                                              3,
                                                              eCurringItem._id
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.editItem
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "mt-4 py-0 text-left",
                                  attrs: { cols: "12", sm: "12" },
                                },
                                [
                                  _c("h3", { staticClass: "black--text" }, [
                                    _vm._v("Extra betalingen"),
                                  ]),
                                  _c("create-payment", {
                                    attrs: { item: _vm.item },
                                    on: { save: _vm.getOneTimePayments },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "mr-2" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "blue darken-1", text: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close", {
                    hasChanges: _vm.hasChanges,
                    contractId: _vm.contractId,
                  })
                },
              },
            },
            [_vm._v(" Annuleren ")]
          ),
          _vm.editContract
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "blue darken-1",
                    text: "",
                    disabled: !_vm.isComplete,
                    loading: _vm.isLoading,
                  },
                  on: { click: _vm.saveClicked },
                },
                [
                  _vm.editItem
                    ? _c("span", [_vm._v("Contract aanpassen")])
                    : !_vm.extension
                    ? _c("span", [_vm._v("Contract aanmaken")])
                    : _c("span", [_vm._v("Contract verlengen")]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }