var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c("span", { staticClass: "headline" }, [
          _vm._v("Aanpassen/Toevoegen Bedrijf"),
        ]),
      ]),
      _vm.editedItem
        ? _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Omniboxx Id",
                              required: "",
                            },
                            model: {
                              value: _vm.editedItem.omniboxxId,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedItem, "omniboxxId", $$v)
                              },
                              expression: "editedItem.omniboxxId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Naam",
                              required: "",
                            },
                            model: {
                              value: _vm.editedItem.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedItem, "name", $$v)
                              },
                              expression: "editedItem.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Rekeningnummer",
                              required: "",
                            },
                            model: {
                              value: _vm.editedItem.bankAccount,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedItem, "bankAccount", $$v)
                              },
                              expression: "editedItem.bankAccount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "naam rekeninghouder",
                              required: "",
                            },
                            model: {
                              value: _vm.editedItem.accountName,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedItem, "accountName", $$v)
                              },
                              expression: "editedItem.accountName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label:
                                "API key Mollie (enkel aanpassen indien je weet wat je doet)",
                              required: "",
                            },
                            model: {
                              value: _vm.editedItem.key,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedItem, "key", $$v)
                              },
                              expression: "editedItem.key",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label:
                                "Twikey API ley (enkel aanpassen indien je weet wat je doet)",
                            },
                            model: {
                              value: _vm.editedItem.twikeyApiKey,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedItem, "twikeyApiKey", $$v)
                              },
                              expression: "editedItem.twikeyApiKey",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label:
                                "Twikey Profile Template ID (enkel aanpassen indien je weet wat je doet)",
                            },
                            model: {
                              value: _vm.editedItem.twikeyCtId,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedItem, "twikeyCtId", $$v)
                              },
                              expression: "editedItem.twikeyCtId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label:
                                "Twikey API Base URL (enkel aanpassen indien je weet wat je doet)",
                            },
                            model: {
                              value: _vm.editedItem.twikeyBaseApiUrl,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editedItem,
                                  "twikeyBaseApiUrl",
                                  $$v
                                )
                              },
                              expression: "editedItem.twikeyBaseApiUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label:
                                "Twikey Plan (enkel aanpassen indien je weet wat je doet)",
                            },
                            model: {
                              value: _vm.editedItem.twikeyPlan,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedItem, "twikeyPlan", $$v)
                              },
                              expression: "editedItem.twikeyPlan",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "blue darken-1", text: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "blue darken-1", text: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("save", _vm.editedItem)
                },
              },
            },
            [_vm._v(" Save ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }