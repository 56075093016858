var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [_c("h3", [_vm._v("Controleer dit passport")])]),
      _c(
        "v-card-text",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "ma-5", attrs: { md5: "" } },
                [
                  _c("h2", { staticClass: "text-center mb-3" }, [
                    _vm._v(" VOORKANT "),
                  ]),
                  _c("v-img", {
                    attrs: {
                      src: _vm.basicUrl + _vm.item.tenant.passport.frontImg,
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "radio-tile-group" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "green", outlined: !_vm.front },
                          on: {
                            click: function ($event) {
                              _vm.front = true
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("mdi-checkbox-marked-circle-outline"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "red", outlined: _vm.front },
                          on: {
                            click: function ($event) {
                              _vm.front = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("mdi-close-circle-outline"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "ma-5", attrs: { md5: "" } },
                [
                  _c("h2", { staticClass: "text-center mb-3" }, [
                    _vm._v(" ACHTERKANT "),
                  ]),
                  _c("v-img", {
                    attrs: {
                      src: _vm.basicUrl + _vm.item.tenant.passport.backImg,
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "radio-tile-group" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "green", outlined: !_vm.back },
                          on: {
                            click: function ($event) {
                              _vm.back = true
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("mdi-checkbox-marked-circle-outline"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "red", outlined: _vm.back },
                          on: {
                            click: function ($event) {
                              _vm.back = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("mdi-close-circle-outline"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "mr-2" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "secondary", outlined: "" },
              on: { click: _vm.downloadImages },
            },
            [_vm._v("Download Afbeeldingen")]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.saveClicked } },
            [_vm._v("Controle Opslaan")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }