var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "", xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "card pa-4" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header d-flex justify-space-between align-center",
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "secondary-text text-lg-left form-title ml-4",
                        },
                        [_vm._v("Mail Templates")]
                      ),
                      _vm.canEdit
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: { "x-large": "", color: "blue-grey" },
                              on: {
                                click: function ($event) {
                                  _vm.selectedMailTemplate = null
                                  _vm.showMailTemplateDialog = true
                                  _vm.isEdit = false
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", left: "", dark: "" } },
                                [_vm._v("mdi-note-plus-outline")]
                              ),
                              _vm._v(" Aanmaken "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { attrs: { flat: "", elevation: 0 } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "d-flex justify-space-between px-0" },
                        [
                          _c("filter-data-dialog-component", {
                            staticClass: "filter--container",
                            attrs: {
                              acceptedFilters: _vm.acceptedFilters,
                              uniqueFilterStoreName: "filter$mailTemplateList",
                              showFilters: false,
                              showSearchBar: true,
                            },
                            on: { filterData: _vm.setFilterOnList },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-data-table",
                        {
                          attrs: {
                            items: _vm.mailTemplates,
                            options: _vm.options,
                            headers: _vm.headers,
                            "server-items-length": _vm.totalCount,
                            loading: _vm.isLoading,
                            "footer-props": {
                              "items-per-page-options": [5, 10, 15],
                            },
                          },
                          on: {
                            "update:options": function ($event) {
                              _vm.options = $event
                            },
                            "update:page": _vm.setSkip,
                            "update:items-per-page": _vm.setLimit,
                            "update:sort-by": _vm.setSort,
                            "update:sort-desc": _vm.setSort,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "item.edit",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.selectedMailTemplate = item
                                              _vm.showMailTemplateDialog = true
                                              _vm.isEdit = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { medium: "", dark: "" } },
                                            [_vm._v("mdi-note-edit-outline")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.delete",
                              fn: function ({ item }) {
                                return [
                                  !item.backendKey
                                    ? _c(
                                        "td",
                                        [
                                          _vm.canEdit
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.selectedMailTemplate =
                                                        item
                                                      _vm.showDelete = true
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        medium: "",
                                                        dark: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-delete-outline"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "template",
                            { slot: "no-data" },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    value: true,
                                    color: "grey",
                                    icon: "mdi-warn",
                                  },
                                },
                                [
                                  _vm._v(
                                    " Sorry, geen mail templates gevonden "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showMailTemplateDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700px", scrollable: "", persistent: "" },
              model: {
                value: _vm.showMailTemplateDialog,
                callback: function ($$v) {
                  _vm.showMailTemplateDialog = $$v
                },
                expression: "showMailTemplateDialog",
              },
            },
            [
              _c("mail-template-dialog", {
                attrs: {
                  mailTemplate: _vm.selectedMailTemplate,
                  isEdit: _vm.isEdit,
                  canEdit: _vm.canEdit,
                },
                on: {
                  save: _vm.upsertTemplate,
                  close: function ($event) {
                    _vm.showMailTemplateDialog = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.showDelete,
            callback: function ($$v) {
              _vm.showDelete = $$v
            },
            expression: "showDelete",
          },
        },
        [
          _vm.showDelete
            ? _c("confirm-delete", {
                attrs: { item: _vm.selectedMailTemplate },
                on: {
                  cancel: function ($event) {
                    _vm.selectedMailTemplate = null
                    _vm.showDelete = false
                  },
                  delete: _vm.deleteTemplate,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }