var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "", xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "card pa-4" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header d-flex justify-space-between align-center",
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass: "secondary-text text-lg-left form-title",
                        },
                        [_vm._v("Eigenaars / Bedrijven")]
                      ),
                      _vm.canEdit
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: { "x-large": "", color: "blue-grey" },
                              on: {
                                click: function ($event) {
                                  _vm.dialog = true
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", left: "", dark: "" } },
                                [_vm._v("mdi-plus")]
                              ),
                              _vm._v(" Nieuw bedrijf toevoegen "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { attrs: { flat: "", elevation: 0 } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "d-flex justify-space-between px-0" },
                        [
                          _c("filter-data-dialog-component", {
                            staticClass: "filter--container",
                            attrs: {
                              acceptedFilters: _vm.acceptedFilters,
                              uniqueFilterStoreName: "filter$companyList",
                              showFilters: false,
                              showSearchBar: true,
                            },
                            on: { filterData: _vm.setFilterOnList },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-data-table",
                        {
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.companiesV2,
                            options: _vm.options,
                            "server-items-length": _vm.totalCompanies,
                            "footer-props": {
                              "items-per-page-options": [5, 10, 15],
                            },
                            loading: _vm.loading,
                            "item-key": "email",
                          },
                          on: {
                            "update:options": function ($event) {
                              _vm.options = $event
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "body",
                              fn: function ({ items }) {
                                return [
                                  _c(
                                    "tbody",
                                    _vm._l(items, function (item) {
                                      return _c("tr", [
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          [_vm._v(_vm._s(item.bankAccount))]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-left" },
                                          [_vm._v(_vm._s(item.accountName))]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "align-end px-0" },
                                          [
                                            _c("v-spacer"),
                                            _vm.canEdit
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openCompanyEdit(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" mdi-account-edit ")]
                                                )
                                              : _vm._e(),
                                            _vm.canEdit
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.confirmDeleteDialogItem =
                                                          item
                                                        _vm.confirmDeleteDialog = true
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" mdi-delete ")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "template",
                            { slot: "no-data" },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    value: true,
                                    color: "grey",
                                    icon: "mdi-warn",
                                  },
                                },
                                [_vm._v(" Sorry, geen eigenaren gevonden ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c("company-edit", {
            attrs: { editedItem: _vm.editedItem },
            on: { save: _vm.save, close: _vm.close },
          }),
        ],
        1
      ),
      _vm.canEdit
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "400px" },
              model: {
                value: _vm.confirmDeleteDialog,
                callback: function ($$v) {
                  _vm.confirmDeleteDialog = $$v
                },
                expression: "confirmDeleteDialog",
              },
            },
            [
              _vm.confirmDeleteDialog
                ? _c("confirm-delete", {
                    attrs: { item: _vm.confirmDeleteDialogItem },
                    on: {
                      cancel: function ($event) {
                        _vm.confirmDeleteDialogItem = null
                        _vm.confirmDeleteDialog = false
                      },
                      delete: _vm.deleteCompany,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }